/** ## Global (amx) */
:root {
  /** ### amateurPool */
  .amateurPool-otm-shuffle {
    --otm-name-font: 600 15px var(--default-font);
    --otm-age-font: 400 13px var(--default-font);
  }

  .amateurPool-feed-item {
    --feed-item-icon-color: #5295f2;
  }

  .amateurPool-sedcard-image {
    --sedcard-image-outline: 2px solid var(--highlight-color-active);
  }

  .amateurPool-selector-by-zip {
    --zip-selector-font: 600 30px Arial, 'Helvetica Neue', Helvetica, sans-serif;
    --zip-selector-fill-hover: #5295f2;
    --zip-selector-text-color: #385898;
    --zip-selector-gradient-color1: #a6a6a6;
    --zip-selector-gradient-color2: #d7d7d7;
    --zip-selector-gradient-color3: rgb(166, 166, 166);
    --zip-selector-gradient-color4: rgb(215, 215, 215);
  }

  .amateurPool-sedcard-header {
    --sedcard-heading-font: 600 21px var(--default-font);
    --sedcard-heading-from-font: 400 14px var(--default-font);
    --sedcard-heading-from-color: #a1a1a1;
  }

  /** ### layout */
  .layout-container {
    --container-gap: 10px;
  }

  .layout-textlink {
    --link-text-decoration: none;
    --link-hover-text-decoration: underline;
  }

  .layout-header {
    --header-bg-color: #385898;
    --header-border-bottom: 1px solid black;
    --header-menu-border-color: #5671a7;
    --header-menu-button-bg-color: transparent;
    --header-menu-button-icon-color: white;
    --header-menu-button-hover-icon-color: #5295f2;

    --logo-xs-url: url('img/logo-xs.png');
    --logo-url: url('img/logo.png');
    --logo-max-width: 220px;

    --header-label-color: white;
    --header-label-font-size: 12px;
    --header-label-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

    @media (min-width: 768px) {
      > .menu-button > .icon {
        margin: 0 20px;
      }
    }

    > .logo {
      margin: 0 auto 0 0;
    }

    &.user-header {
      --games-button-value-bg: #ffcf70;
      --games-button-value-color: black;
      --coins-button-value-bg: #42b72a;
      --coins-button-value-color: white;
      --messenger-button-value-bg: #5295f2;
      --messenger-button-value-color: white;
      --cams-button-value-bg: #cc7a37;
      --cams-button-value-color: white;
      --vip-button-value-bg: #d0c4ff;
      --vip-button-value-color: black;
      --friends-button-value-bg: #c80000;
      --friends-button-value-color: white;
    }

    &.guest-header {
      --header-input-border: 1px solid #29416f;
      --header-input-focus-border: var(--header-input-border);
      --header-input-background: #fff;

      .layout-textlink {
        --link-color: #fff;
        font-size: 12px;
      }
    }
  }

  .layout-input {
    --input-font: 400 13px var(--default-font);
    --input-border: var(--default-border);
    --input-padding: 5px;
    --input-bg-focus: #fff;
    --input-border-focus: 1px solid #5295f2;
    --input-boxshadow-focus: 0 0 5px #0009;
  }

  .layout-item {
    --item-background: var(--background);
    --item-background-hover: var(--background);
    --item-background-active: var(--background);
    --item-border: var(--default-border);
    --item-border-width: 1px;
    --item-border-radius: var(--default-border-radius);
    --item-highlight-color: var(--highlight-color);
    --item-highlight-color-hover: var(--highlight-color-active);
    --item-highlight-color-active: var(--highlight-color-active);
    --item-outline-active: 2px solid var(--highlight-color-active);
    --item-font: 12px var(--default-font);
    --item-heading-font: 600 14px var(--default-font);
  }

  .layout-link {
    --link-background: var(--background);
    --link-color: var(--highlight-color);
    --link-color-hover: var(--highlight-color-active);
    --link-border: var(--default-border);
    --link-border-radius: var(--default-border-radius);
    --link-font: 600 14px var(--default-font);
    --link-outline-active: 2px solid var(--highlight-color-active);

    &.preset-button {
      --button-reject-bg: #c80000;
      --button-reject-border: none;
    }
  }

  .layout-note {
    --layout-note-info-background: #42b72a;
    --layout-note-info-color: white;
    --layout-note-warn-background: #385898;
    --layout-note-warn-color: white;
    --layout-note-error-background: #c80000;
    --layout-note-error-color: white;
  }

  .layout-panel {
    --panel-padding: 10px;
    --panel-gap: 10px;
    --panel-background: var(--background);
    --panel-border: var(--default-border);
    --panel-border-radius: var(--default-border-radius);
    --panel-heading-font: var(--text-strong) 15px var(--headline);
    --panel-heading-font-color: var(--highlight-color);
    --panel-subheading-font: 600 13px var(--default-font);
    --panel-subheading-color: #5295f2;
  }

  .layout-toolbar {
    &.layout-tabs {
      --menu-item-label-font: 14px var(--default-font);
      --submenu-item-height: 34px;
      --tab-active-font-weight: 600;
   }

    &.nova-dots {
      --dots-color: white;
      --dots-active-color: var(--primary-color);
    }
  }

  .scroll-min .layout-toolbar.layout-tabs.sticky {
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  .layout-price {
    --price-font: 600 13px var(--headline);
  }

  .layout-input-toggle {
    --toggle-bg-off: #c80000;
    --toggle-bg-on: #42b72a;
    --toggle-color-on: white;
  }

  /** - toolbar */
  --toolbar-icon-color: var(--highlight-color);
  --toolbar-icon-color-hover: var(--highlight-color-active);

  /** - toolbar-list */
  --toolbar-list-font: 600 13px var(--headline);
  --toolbar-list-background: var(--background);
  --toolbar-list-background-without-hover: var(--background);

  /** ### FMOTD */
  .fmotd-upcoming {
    --fmotd-upcoming-title-font: 600 13px var(--headline);
    --fmotd-upcoming-title-shadow: 1px 1px 2px rgba(255, 255, 255, 0.6);
  }

  /** ### userpool */
  .userpool-mailing-config {
    --mailing-config-primary-color: #5295f2;
    --mailing-config-primary-font: 600 15px var(--headline);
    --mailing-config-secondary-color: #1d2129;
    --mailing-config-secondary-font: 600 14px var(--headline);
    --mailing-config-warning-font: 600 14px var(--default-font);
    --mailing-config-warning-color: #1d2129;
  }

  /** regard */
  .layout-item.regard {
    --regard-text-size: 12px;
  }

  /** ### moviePool */
  .fmotd-countdown {
    --fmotd-countdown-color: #5295f2;
    --fmotd-countdown-text-shadow: 0 0 2px #385898;
  }
}

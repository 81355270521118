.ons-layout.columns > .box > .tabs {
  padding-bottom: 3px;
}
.ons-layout.tabs > .box > .ons-item > .box {
  font-size: 14px;
  padding: 0 15px;
  height: 32px;
  border: 1px solid white;
  border-radius: var(--default-border-radius);
  color: #385898;
  text-decoration: none;
}

@media (max-width: 448px) {
  .ons-layout.tabs > .box > .ons-item > .box {
    padding: 0 6px;
  }
}

.ons-layout.tabs > .box > .ons-item > .box > .icon {
  width: 24px;
  height: 24px;
}

.ons-layout.tabs > .box > .ons-item > .box > .icon * {
  fill: #385898;
}

body.desktop .ons-layout.tabs > .box > .ons-item:not(.active) > .box:not(.layout-active):hover {
  background: white;
}

.ons-layout.tabs > .box > .ons-item > .box.layout-active,
.ons-layout.tabs > .box > .ons-item.active > .box {
  background: #5295f2;
  border-color: #243963;
  color: white;
  font-weight: 600;
}

.ons-layout.tabs > .box > .ons-item > .box.layout-active > .icon *,
.ons-layout.tabs > .box > .ons-item.active > .box > .icon * {
  fill: white;
}

@media (max-width: 1023px) {
  .ons-layout.tabs > .box.sedcard-nav-box {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .ons-layout.tabs.regardShop > .box {
    padding: 0 8px;
  }
}

.ons-layout.tabs.messenger-widget > .box,
.ons-layout.tabs.regardShop > .box {
  padding: 0 8px;
  height: 46px;
}

.ons-layout.tabs.regardShop > .box {
  margin: 0;
}
.ons-layout.tabs.messenger-widget > .box  > :last-child > .box,
.ons-layout.tabs.regardShop > .box > :last-child > .box {
  width: 32px;
  padding: 0 !important;
}

.regard.shop .tabs {
  background: #e9ebee;
}
.regard.shop > .ons-layout.tabs > .box > .ons-item {
  padding: 0;
}


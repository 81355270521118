[onsw-stock-indicator] {
  pointer-events: none;
}

[onsw-stock-indicator].error,
[onsw-stock-indicator].busy,
[onsw-stock-indicator].valid>.box>.value {
  display: none !important;
}

[onsw-stock-indicator]>.box>.icon {
  height: 28px;
  width: 28px;
  vertical-align: top;
}

[onsw-stock-indicator]>.box>.value>.icon {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  padding-left: 2px;
}

[onsw-stock-indicator].picture>.box>.icon {
  position: absolute;
  bottom: 3px;
  right: 3px;
}

[onsw-stock-indicator].picture.invalid>.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[onsw-stock-indicator].picture.invalid>.box>.label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[onsw-stock-indicator].movieDetail {
  position: relative;
  padding: 0 !important;
}

onsw-movie-player.playing ~ [onsw-stock-indicator] {
  display: none;
}

.fmotd-teaser-widget [onsw-stock-indicator].fmotd>.box,
.media-widget [onsw-stock-indicator].fmotd>.box {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
}

.media-widget [onsw-stock-indicator].fmotd>.box {
  bottom: 32px;
}

@media (min-width: 1600px) {
  .fmotd-teaser-widget [onsw-stock-indicator].fmotd>.box {
    bottom: unset;
    top: 0;
    background-position: left;
  }
}

.next1 [onsw-stock-indicator],
.next2 [onsw-stock-indicator],
.free-movie-tile [onsw-stock-indicator] {
  display: none;
}

[onsw-stock-indicator].movieDetail>.box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

[onsw-stock-indicator].fmotd>.box {
  padding-top: 20%;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

[onsw-stock-indicator].fmotd>.box>.icon {
  display: none;
}

[onsw-stock-indicator].fmotd {
  filter: unset!important;

  > .box {
    background-image: var(--freemovie-logo);
  }
}

[onsw-stock-indicator].movieDetail>.box>.icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

[onsw-stock-indicator].picture>.box>.value,
[onsw-stock-indicator].movieDetail>.box>.value {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 3px 5px;
}

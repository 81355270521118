.messengerDialog.online > .icon.online,
.messengerDialog.online > .icon.offline {
  left: 56px;
  top: 56px;
  z-index: 1;
}

@media (max-width: 767px) {
  .messenger-dialog.short .online.messengerDialog > .icon.online,
  .messenger-dialog.short .online.messengerDialog > .icon.offline  {
    width: 50px;
    top: 64px;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateX(50%);
  }
  .messenger-dialog:not(.short) .online.messengerDialog > .icon.online,
  .messenger-dialog:not(.short) .online.messengerDialog > .icon.offline {
    left: 34px;
    top: 34px;
  }
}

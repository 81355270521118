.fmotd-countdown {
  &.layout-init,
  &.layout-inactive {
    display: none;
  }

  > .time-left {
    color: var(--fmotd-countdown-color);
    text-shadow: var(--fmotd-countdown-text-shadow);
  }
}

[onsw-chosen] > .box > .info,
.ons-form > .box > label> input:not([type="checkbox"]),
.ons-form > .box > label> file-upload,
.ons-form > .box > label> textarea,
.ons-form > .box > label> select {
  font-family: Arial, 'Helvetica Neue', sans-serif;
  font-size: 13px;
  font-weight: 400;
  background: var(--input-bg);
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  padding: 5px;
  margin-top: 5px;
}
.ons-form > .box > label> file-upload {
  padding: 2px 5px;
}

[onsw-chosen] > .box > .selected {
  margin-top: 5px;
}

.ons-form > .box > label> input:not([type="checkbox"]):focus,
.ons-form > .box > label> file-upload:focus,
.ons-form > .box > label> textarea:focus,
.ons-form > .box > label> select:focus {
  background: var(--input-bg-focus);
  border: var(--input-border-focus);
  box-shadow: var(--input-boxshadow-focus);
}

[onsw-chosen] > .box > .info {
  height: 34px;
  line-height: 24px;
}
.ons-form > .box > label> file-upload,
.ons-form > .box > label> input:not([type="checkbox"]),
.ons-form > .box > label > select {
  height: 34px;
}

.ons-form > .box > label> file-upload {
  line-height: 30px;
}

file-upload:before {
  padding: 1px 10px 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/*file-upload > div {*/
/*  vertical-align: unset;*/
/*}*/

.ons-form.inline > form > label > input[type="password"],
.ons-form.inline > form > label > input[type="text"] {
  margin-top: 0;
  margin-right: 10px;
}

[onsw-chosen] > .box > .selected > .option, 
[onsw-chosen] > .box > .selected > .add {
  background: #5295f2;
  border-radius: var(--default-border-radius);
}

[onsw-chosen] > .box > .selected > .add > span,
[onsw-chosen] > .box > .selected > .option::after {
  color: white;
}

[onsw-chosen].has-selected-options > .box > .selected {
  padding: 1px 0;
}
[onsw-chosen] > .box > .available > .box > .option:hover {
  background: #edf4fe;
  color: #1d2129;
}

[onsw-signup] > .box > label > span {
  font-weight: 600;
}

.userPool-login.ons-form > .box > label > input {
  background: white !important;
  border: 1px solid #29416f !important;
}

.ons-form.inlineFormContainer > form > label {
  max-width: 370px;
}

.ons-form > .box > label > .validation {
  border-radius: 0 0 3px 3px;
}

.passwordSetForm.ons-image > .box {
  border-radius: var(--default-border-radius);
}

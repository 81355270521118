.ons-item.movie-hd {
  position: absolute;
  right: 5px;
  top: 0;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  font-style: italic;
  text-shadow: 2px 2px 1px black;
}

.ons-item.movie-hd.movieDetail {
  position: relative;
  top: unset;
  right: unset;
}

.ons-item.movie-hd.movieDetail > .box {
  position: absolute;
  margin-bottom: 56%;
  right: 10px;
  bottom: -32px;
}

.fmotd-main {
  > .top {
    > .layout-panel.premium {
      text-align: center;

      > :last-child.layout-link.preset-button {
        place-self: center;
      }
    }

    &.large {
      grid-template-columns: 7fr 3fr;

      section > .fmotd-upcoming > article::after {
        place-self: start start;
      }

      .fmotd-teaser-box {
        grid-template-rows: 240px 240px;
      }

      .layout-panel.premium {
        --panel-gap: 20px;
        align-content: center;
        place-self: stretch;

        > :first-child {
          order: -1;
        }

        &::before {
          content: '';
          background: var(--freemovie-logo) center/contain no-repeat;
          padding-top: calc(8 / 15 * 100%);
        }
      }

      > .previous,
      > .info {
        grid-column: 1 / -1;
      }
    }
  }

  > .panel-list > .warp-grid.previous {
    --warp-gap: var(--warp-gap-default, 2px);
    --warp-min-width: 280px;
    --warp-aspect-ratio: 4 / 3;
    --warp-aspect-ratio-fallback: 75%;

    > article {
      > .layout-item {
        height: 100%;
        cursor: pointer;
        grid-template: 1fr [toolbar] min-content / minmax(0, 1fr);
        overflow: hidden;
        align-items: stretch;

        > * {
          grid-column: 1 / -1;
          position: relative;
        }

        > img {
          grid-row: 1 / -1;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: transform 1s 0s ease;
        }

        @media (hover: hover) {
          &:hover {
            > img {
              transition-delay: 0.2s;
              transform: scale(1.2);
            }
          }
        }

        > .top {
          grid-row: 1 / toolbar;
          overflow: hidden;
        }

        > .toolbar-list {
          grid-row: toolbar / -1;
        }
      }
    }
  }
}

/* BONUS */
.nova-teaser.bonus .countdown,
[onsw-countdown].bonus {
  position: absolute;
  bottom: 10px;
  right: 10px;
  top: auto;
  left: auto;

  > .box {
    background: var(--countdown-default-bg);
    color: var(--countdown-default-color);
    font-family: var(--headline);
    font-size: var(--countdown-default-font-size);
    font-weight: var(--countdown-default-font-weight);
    padding: 0 10px;
    border: var(--countdown-default-border);
    box-shadow: var(--countdown-default-box-shadow);
    text-shadow: var(--countdown-default-text-shadow);
    border-radius: var(--default-border-radius);

    @media (min-width: 1024px) {
      font-size: var(--countdown-sidebar-font-size);
    }

    > .value {
      animation: pulsate 2s ease-out infinite;
    }

    @keyframes pulsate {
      0% { opacity: 0.5; }
      50% { opacity: 1.0; }
      100% { opacity: 0.5; }
    }
  }
}

/* BONUS OVERLAY */
.bonusOverlay .nova-teaser.bonus .countdown,
.bonusOverlay > .box > [onsw-countdown].bonus {
  bottom: 15px;
}

/* BONUS SIDEBAR */
.sidebar-box .nova-teaser.bonus .countdown,
.sidebar-box [onsw-countdown].bonus {
  bottom: var(--countdown-sidebar-bottom-position);
  top: var(--countdown-sidebar-top-position);
  right: unset;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: var(--countdown-sidebar-font-size);
}

/* BONUS WELLCUM */
.nova-teaser.bonus.WellCum .countdown,
[onsw-countdown].bonus.WellCum {
  bottom: 10px;
  right: 10px;
  top: auto;
  left: auto;

  > .box {
    background: var(--countdown-wellcum-bg);
    color: var(--countdown-wellcum-color);
    border: var(--countdown-wellcum-border);
    box-shadow: var(--countdown-wellcum-box-shadow);
    text-shadow: var(--countdown-wellcum-text-shadow);
  }
}

.sidebar-box .nova-teaser.bonus.WellCum .countdown,
.sidebar [onsw-countdown].bonus.WellCum {
  bottom: var(--countdown-sidebar-wellcum-bottom-position);
  top: var(--countdown-sidebar-wellcum-top-position);
  right: unset;
  left: 50%;
  transform: translate(-50%,-50%);
}

/* BONUS HOME --> was ist das?*/
[onsw-bonus].home > .box > [onsw-countdown] {
  bottom: 0;
  right: 0;
  line-height: 32px;
  z-index: 1;
}

@media (min-width: 1024px) {
  [onsw-bonus].home > .box > [onsw-countdown] {
    line-height: 50px;
  }
}

/* TEASER */
.nova-teaser.special .countdown > .box,
[onsw-countdown].teaser-widget > .box {
  position: absolute;
  /*right: 0;*/
  left: auto;
  bottom: 0;
  /*top: 0;*/
  width: max-content;
  height: max-content;
  /*margin: auto;*/
  text-align: center;
  display: inline-block;
  padding: 15px 30px;
  color: white;
  filter: drop-shadow(4px 8px rgba(0, 0, 0, .7));
  font-family: var(--headline);
}

.sidebar-box .nova-teaser .countdown > .box,
.sidebar-box [onsw-countdown].teaser-widget > .box {
  top: 11%;
}

[onsw-countdown].teaser-widget > .box > .label {
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  display: block;
  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 14px;
  }
}

[onsw-countdown].teaser-widget > .box > .value {
  line-height: 50px;
  display: block;
  white-space: nowrap;
  font-size: 50px;
  @media (max-width: 1023px) {
    font-size: 30px;
    line-height: 35px;
  }
}

/* May 2024*/
[onsw-countdown].teaser-widget.summer2024_preview > .box{
  background:#D7861A;
  left:auto;
  right:0;
  top: auto;
  bottom: 0;
  filter: drop-shadow(4px 8px 4px rgba(0, 0, 0, .7));
}
.sidebar [onsw-countdown].teaser-widget > .box {
  bottom: 10%;
  filter: none;
  width: 100%;
}

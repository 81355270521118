.ons-layout.slideshow > .box > .ons-image > .box {
  border-radius: var(--default-border-radius);
}

[onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
  background-image: none;
  background-color: white;
  padding: 5px 5px 10px;
}
[hookname="welcomeGuest"] > .box {
  text-shadow: none;
  color: white;
  border: var(--default-border);
  border-radius: var(--default-border-radius);
}
[hookname="welcomeGuest"] > .box > div {
  position: relative;
}
[hookname="welcomeGuest"] > .box > div > .ons-heading > .box {
  font-family: 'Pattaya', sans-serif;
  font-size: 30px;
  color: #5295f2;
  text-shadow: 2px 2px 2px white,2px -2px 2px white,-2px 2px 2px white,0 2px 4px rgba(56,88,152,.5);
  margin: 0;
  text-align: center;
}
[hookname="welcomeGuest"] > .box > div > .formContainer {
  position: relative;
  background: rgba(0,0,0,.7);
  padding: 10px;
  border-radius: var(--default-border-radius);
}
[hookname="welcomeGuest"] > .box > div > .formContainer > onsw-template {
  bottom: 55px;
  position: absolute;
  font-size: 11px;
  line-height: 1;
}
[hookname="welcomeGuest"] .ons-layout.buttons {
  text-align: left;
  padding-top: 30px;
}
[hookname="welcomeGuest"] .ons-item.button > .box {
  border: transparent;
  background: #42b72a;
}

@media (max-width: 619px) {
  [hookname="welcomeGuest"].ons-layout.columns > .box > .col60-xs {
    width: 100%;
  }
}
@media (min-width: 620px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
    background-image: url(../img/signup_background.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
  }
}
@media (min-width: 1024px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg {
    padding-top: 28%;
    position: relative;
  }
}
@media (max-width: 1279px) {
  [hookname="welcomeGuest"].ons-layout.col50-lg > .box {
    margin: 0 -1px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  [hookname="welcomeGuest"] > .box > div {
    bottom: calc(50% - 125px);
    top: calc(50% - 125px);
    margin-left: 20px;
  }
  [hookname="welcomeGuest"] > .box > div > .formContainer {
    padding: 5px 10px 10px;
  }
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    padding: 10px 0;
  }
  [hookname="welcomeGuest"] [onsw-text] {
    display: none;
  }
}
@media (min-width:1280px) and (max-width:1366px) {
  [hookname="welcomeGuest"] [onsw-text]>.box {
    font-size: 12px;
    line-height: 1.2;
  }
}
@media (min-width: 1367px) and (max-width:1599px) {
  [hookname="welcomeGuest"] > .box > div {
    bottom: calc(50% - 165px);
    top: calc(50% - 165px)
  }
}
@media (min-width: 1460px) and (max-width: 1599px) {
  [hookname="welcomeGuest"] > .box > div > .ons-heading > .box {
    font-size: 40px;
  }
}
@media (max-width: 1599px) {
  [hookname="welcomeGuest"] form > label > span {
    display: none;
  }
  [hookname="welcomeGuest"] [onsw-signup] .visibility {
    position: absolute;
    top: 4px;
  }
}
@media (min-width: 1600px) {
  [hookname="welcomeGuest"] > .box > div {
    margin:0 20px;
    bottom: calc(50% - 200px);
    top: calc(50% - 200px)
  }
  [hookname="welcomeGuest"] > input::-webkit-input-placeholder {
    color: transparent !important;
  }
  [hookname="welcomeGuest"] input::-moz-placeholder {
    color: transparent !important;
  }
  [hookname="welcomeGuest"] input:-moz-placeholder {
    color: transparent !important;
  }
  [hookname="welcomeGuest"] input:-ms-input-placeholder {
    color: transparent !important;
  }
  [hookname="welcomeGuest"] ::placeholder {
    color: transparent !important;
  }
  [hookname="welcomeGuest"] .ons-form > form > label > span {
    display: inline-block;
  }
}
@media (min-width: 1920px) {
  [hookname="welcomeGuest"] > .box > div {
    margin:0 40px;
    bottom: calc(50% - 220px);
    top: calc(50% - 220px)
  }
  [hookname="welcomeGuest"] > .box > div > .formContainer {
    padding: 5px 20px 20px;
  }
  [hookname="welcomeGuest"] .ons-layout.columns > .box > .col50-xs {
    width:360px !important
  }
  [hookname="welcomeGuest"] [onsw-text]>.box {
    margin: 15px 0;
    line-height: 1.4142
  }
  [hookname="welcomeGuest"] > .box > div > .formContainer > onsw-template {
    bottom: 65px;
  }
  [hookname="welcomeGuest"] > .box > div > .ons-heading > .box {
    font-size: 40px;
  }
}

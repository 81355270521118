@import '../layout/container.css';
@import '../layout/panel.css';

.layout-container.picture-set-detail,
.layout-container.movie-detail {

  > .layout-panel {
    > .heading.fmotd-countdown {
      text-align: right;

      > .time-left {
        font-size: 40px;
      }
    }
  }

  > .side.layout-container > .layout-panel:not(.messenger-panel) {
    position: relative;

    .button {
      text-align: right;
    }

    > .layout-container.layout-overlay {
      top: auto;
      left: auto;
      margin: var(--panel-padding);
      width: 300px;
    }
  }

  &.large {
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);

    > .side.layout-container {
      grid-area: 1 / 2 / 4 / 2;
    }
  }
}

.payment.payback .table {
  border-radius: var(--default-border-radius);
}
.payment.payback h2 {
  color: #385898;
  font-size: 14px;
  margin: 0 0 10px;
}

.payment.payback p.intro {
  margin: 0 0 10px;
}

.payment.payback p span {
  color: #385898;
  font-weight: bold;
}

.payment.payback p.help-block {
  font-size: 11px;
  font-weight: normal;
  margin: 0;
}

.box > .notify {
  border: none!important;
  background: #42b72a;
  color: white;
  top: 3px;
  left: 0;
  right: 0;

  .icon.close * {
    fill: white;
  }
}

.error > .box > .notify {
  background: #c80000;
}


@font-face {
  font-family: 'Pattaya';
  font-style: normal;
  font-weight: 400;
  src: local('Pattaya'), local('Pattaya-Regular'), url(../fonts/pattaya.ttf) format('truetype');
}

@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 400;
  src: local('PTSansNarrow'), local('PTSansNarrow-Regular'), url(../fonts/PTSansNarrow-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 700;
  src: local('PTSansNarrow'), local('PTSansNarrow-Bold'), url(../fonts/PTSansNarrow-Bold.ttf) format('truetype');
}

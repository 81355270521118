onsw-more-about-me h4 {
  font-size: 18px;
  margin: 0 0 10px;

  &:last-of-type {
    margin-top: 15px;
  }
}

onsw-more-about-me dl {
  margin-top: 10px;
}
onsw-more-about-me dt {
  font-weight: 600;
  color: #5295f2;
  margin-bottom: 5px;
}

onsw-more-about-me ul {
  margin: 0 0 0 20px;
  padding-left: 10px;
}

onsw-more-about-me li {
  margin: 5px;
  list-style-image: var(--checkmark-url);
}

onsw-more-about-me dd {
  margin: 0;
}

onsw-more-about-me h4 {
  font-size: 15px;
  color: #385898;
}

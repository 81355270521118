[onsw-bonus] > .box {
  border-radius: var(--default-border-radius);
  /*border: var(--default-border);*/
  overflow: hidden;
}

[onsw-bonus].payment > .box > .ons-image > .box {
  padding-top: 200px;
}

[onsw-bonus].bonusOverlay > .box {
  border: none;
  border-radius: var(--default-border-radius);
}

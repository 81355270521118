@media (min-width: 1024px) {
  .above-movies {
    display: none;
  }
}

.fmotd-upcoming {
  --fmotd-upcoming-background: var(--panel-background);

  gap: 10px;
  grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);
  grid-template-rows: 180px 180px;
  background: var(--fmotd-upcoming-background);

  &.large {
    grid-template-rows: 240px 240px;
  }

  &.small {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: 370px 180px;

    > article:first-child {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
  }

  &.tiny {
    grid-template-rows: 260px 140px;
  }

  > article {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content min-content;
    overflow: hidden;
    border-radius: var(--default-border-radius);
    border: var(--default-border);

    > * {
      grid-column: 1 / 2;
    }

    > img {
      grid-row: 1 / 3;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > h3 {
      grid-row: 2 / 3;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: unset;
      padding: 5px;
      background: linear-gradient(to bottom, transparent, var(--fmotd-upcoming-background));
      font: var(--fmotd-upcoming-title-font);
      text-shadow: var(--fmotd-upcoming-title-shadow);
    }

    &:hover {
      > h3 {
        color: var(--fmotd-upcoming-title-hover-color);
      }
    }

    > p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 5px 5px;
    }

    > button {
      display: none;
    }

    &:first-child {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
      grid-template-columns: 1fr min-content;

      &:not(:empty)::after {
        content: '';
        background: var(--freemovie-logo) center/contain no-repeat;
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        place-self: end;
        width: 40%;
        padding-top: 20%;
      }

      > img,
      > h3 {
        grid-column: 1 / 3;

        > .date {
          display: none;
        }
      }

      > p {
        -webkit-line-clamp: 4;
      }

      > button {
        display: block;
        grid-column: 2 / 3;
        place-self: end;
        white-space: nowrap;
        margin: 10px;
      }
    }
  }
}

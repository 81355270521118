@import '../css/layout/link.css';

.layout-link.preset-button {
  --button-bg: var(--default-btn-bg);
  --button-border: var(--default-btn-border, none);
  --button-hover-bg: var(--default-btn-hover-bg);

  --button-cancel-bg: #979797;
  --button-cancel-border: 1px solid #888;
  --button-cancel-hover-bg: #909090;

  --button-reject-bg: #c00 radial-gradient(#c00, #900);
  --button-reject-border: 1px solid #b00;
  --button-reject-hover-bg: #900;

  --link-padding: var(--default-btn-padding, 3px 15px);
  --link-gap: var(--default-btn-gap, 6px);
  --link-border: var(--button-border);
  --link-border-radius: var(--button-border-radius, var(--default-border-radius, 0));
  --link-color: var(--default-btn-text-color);
  --link-color-hover: var(--default-btn-text-color);
  --link-background: var(--button-bg);
  --link-background-hover: var(--button-hover-bg);
  --link-font: var(--default-btn-font);
  --link-text-transform: var(--default-btn-text-transform);
  --link-filter: var(--default-btn-filter);
  --link-box-shadow: var(--default-btn-boxshadow);

  &.cancel {
    --link-border: var(--button-cancel-border);
    --link-background: var(--button-cancel-bg);
    --link-background-hover: var(--button-cancel-hover-bg);
  }

  &.reject {
    --link-border: var(--button-reject-border);
    --link-background: var(--button-reject-bg);
    --link-background-hover: var(--button-reject-hover-bg);
  }
}

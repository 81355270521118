[onsw-payment-progress].step li.active div,
[onsw-payment-progress].step li.finalized div {
    background: linear-gradient(to bottom, #5295f2 0%, #385898 100%);
}
[onsw-payment-progress].step li.active p {
    color:#5295f2;
}
[onsw-payment-progress].step .bar div {
    background: linear-gradient(to bottom, #5295f2 0%,#385898 100%);
}


/***********/
/* actions */
/***********/
ons-actionslider {
  height: 80px;
}

/*@media (min-width: 1024px) {*/
/*  ons-actionslider .onsas-actions {*/
/*    right: 300px;*/
/*  }*/
/*  body.show-dialog ons-actionslider .onsas-actions {*/
/*    right: 620px;*/
/*  }*/
/*}*/

ons-actionslider .onsas-actions {
  background: none;
}

ons-actionslider li {
  border-radius: var(--default-border-radius);
  border-top: 6px solid transparent;
  box-shadow: 4px -3px 16px rgba(0,0,0,.5);
}

ons-actionslider .onsas-button {
  height: 55px;
}

ons-actionslider li .onsas-image {
  border-radius: 20px;
}

ons-actionslider .icon {
  margin-top: 17.5px;
}

ons-actionslider li, ons-actionslider li .onsas-inner {
  background: #fff;
  font-weight: 600;
}

ons-actionslider li .onsas-message {
  font-weight: normal;
}

ons-actionslider li.onsas-online {
  border-color: #42b72a;
}
ons-actionslider li.onsas-livecam {
  border-color: #5295f2;
}
ons-actionslider li.onsas-buddyconfirm,
ons-actionslider li.onsas-buddyrequest {
  border-color: #c80000;
}
ons-actionslider li.onsas-pictures,
ons-actionslider li.onsas-movies {
  border-color: #385898;
}
ons-actionslider li.onsas-old .onsas-outer {
  opacity: 0.5;
}

ons-actionslider li.onsas-old:hover .onsas-outer {
  opacity: 1;
}
ons-actionslider li .onsas-message {
  font-weight: 400;
}

ons-actionslider .onsas-button {
  margin: 24px 0 0;
  background: #385898;
  height: 56px;
}
ons-actionslider .icon {
  margin-top: 20px;
}

/*
favoriteonline = #f7b80f
weblog = #7560c6
*/

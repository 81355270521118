[onsw-payment-mobile-payment].payment .package > .box,
[onsw-payment-packages].payment .package > .box,
[onsw-payment-methods] .method .box {
  border-radius: var(--default-border-radius);
  border: var(--default-border);
}
[onsw-payment-mobile-payment].payment .package:not(.active):hover > .box,
[onsw-payment-packages].payment .package:hover > .box,
[onsw-payment-methods] .method:hover .box {
  background-color: #edf4fe;
}
[onsw-payment-mobile-payment] .package.active > .box,
[onsw-payment-methods] .method.active .box {
  background-color: #edf4fe;
  border: 2px solid #5295f2;
  color: #1d2129;
}

[hookname="paymentBadges"] > .box {
  border-color: #dddfe2;
}

[onsw-payment-mobile-payment].payment .package > .box  > .ons-item,
[onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item,
[onsw-payment-packages].payment .package > .box  > .ons-item {
  background: #5295f2;
  border-radius: var(--default-border-radius);
}
[onsw-payment-packages].payment .package.packageSelected > .box {
  border-color: #5295f2;
}
[onsw-payment-packages].payment .package .name {
  font-size: 15px;
  color: #385898;
  font-weight: 600;
}
[onsw-payment-mobile-payment].payment .package .value.coins .bonus,
[onsw-payment-packages].payment .package .value.coins .bonus {
  color: #c80000;
}
[onsw-payment-mobile-payment].payment .package.withBonus .value.coins .default:before,
[onsw-payment-packages].payment .package.withBonus .value.coins .default:before {
  border-color: #c80000;
}
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus:after,
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus:after {
  color: #c80000;
}
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus .value {
  font-size: 26px;
}

.ons-item.backLink > .box > .label::before {
  border-right-color: #385898;
}

[onsw-payment-methods] .method .methodVerifiedFlag {
  color: #42b72a;
}

[onsw-payment-methods] .method .methodVerifiedFlag > span {
  color: #5295f2;
}

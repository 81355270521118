[onsw-pagination] > .box {
  line-height: 40px;
  font-size: 15px;
  color: #a2a1a1;
  white-space: nowrap;
  font-weight: 600;
}

[onsw-pagination] > .box > .ons-item > .box {
  margin: 0 2px;
  height: 40px;
  width: unset;
  border-radius: 20px;
  border: none;
  padding: 8px 6px;
  transition: background .3s ease;
  line-height: 24px;
  vertical-align: top;
}

@media (min-width: 1024px) {
  body:not(.show-dialog):not(.regard-shop-open) [onsw-pagination] > .box > .ons-item > .box {
    min-width: 40px;
    padding: 8px;
  }
}

@media (min-width: 1280px) {
  [onsw-pagination] > .box > .ons-item > .box {
    min-width: 40px;
    padding: 8px;
  }
}

body.desktop [onsw-pagination] > .box > .ons-item > .box:hover {
  background: #5295f2;
  color: #fff;
}

[onsw-pagination] > .box > .ons-item.current > .box {
  min-width: 40px;
  background: #385898;
  font-size: 18px;
  color: #fff;
}

/* Pfeile */
[onsw-pagination] > .box > .ons-item.arrow.inactive {
  opacity: .4;
  pointer-events: none;
}

[onsw-pagination] > .box > .ons-item.arrow > .box > .label {
  display: none;
}

[onsw-pagination] > .box > .ons-item.arrow > .box {
  background: white;
  transform: scale(.8);
  padding: 7px !important;
  border: var(--default-border);
}

body.desktop [onsw-pagination] > .box > .ons-item.arrow > .box:hover {
  background: white;
}

[onsw-pagination] > .box > .ons-item.arrow > .box > .icon {
  width: 24px;
  height: 24px;
}

[onsw-pagination] > .box > .ons-item.arrow > .box > .icon * {
  fill: #385898;
}

body.desktop [onsw-pagination] > .box > .ons-item.arrow > .box:hover > .icon * {
  fill: #5295f2;
}


[onsw-hotornot] {
  background: white;
  margin: 10px 10px 10px 5px;
  padding: 10px;
  border: var(--default-border);
  border-radius: var(--default-border-radius);
  position: relative;
}

[onsw-hotornot].ons-layout.group.fixed-main > .box {
  background-image: url(../img/hotornot/logo.png), url(../img/hotornot/bg.jpg);
  background-size: 300px, cover;
  background-repeat: no-repeat;
  background-position: 20px 20px, right;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: calc(100% - 20px);
  border: none;
  border-radius: 0;
}

body:lang(en) [onsw-hotornot].ons-layout.group.fixed-main > .box {
  background-image: url("../img/hotornot/logo_en.png"), url("../img/hotornot/bg.jpg");
}

.hotornot-positives {
  border-top: 2px solid #fff;
}
.hotornot-positives > .box > [onsw-link] > .box {
  background: linear-gradient(to bottom,  rgba(182,224,38,1) 0%,rgba(171,220,40,1) 100%);
  text-shadow: 0 1px 3px #236526;
  text-transform: uppercase;
  color: #fff;
}
.hotornot-positives > .box > [onsw-link] > .box:hover {
  background: linear-gradient(to bottom,  rgba(182,224,38,1) 0%,rgba(171,220,40,1) 100%);
  box-shadow: 0px 5px 5px -3px #191919;
  color: #2d2d2d;
}

.hotornot-pending {
  background: #f0f0f0;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,.3), 0 11px 7px 0 rgba(0,0,0,.19);
  padding: 25px;
  border: 2px solid white;
  border-radius: var(--default-border-radius);
}

[onsw-item].hotornot {
  color: #3a5093;
  font-size: 26px;
  font-weight: 600;
  white-space: nowrap;
}
[onsw-item].hotornot > .box > .value {
  font-size: 20px;
}
.ons-layout.textblock > .box > .ons-heading > .box {
  font-size: 36px;
  color: #5295f2;
}
.hotornot-pending.pos1 > .box > [onsw-text].hotornot.from {
  color: #3a5093;
  font-size: 15px;
  font-weight: 600;
}

.hotbar > .box > .ons-item > .box {
  width: 48px;
  height: 48px;
}

.hotbar > .box > [onsw-pin-button] > .box > .icon *,
.hotbar > .box > onsw-open-dialog-button > .box > .icon *,
.hotbar > .box > [onsw-item] > .box > .icon * {
  fill: #29416e;
}
.hotbar > .box > [onsw-pin-button].active > .box > .icon * {
  stroke: #29416e;
  stroke-width: 1px;
}
.hotornot.icons > .icon:first-child * {
  fill: #fc0000;
}
.hotornot.icons > .icon:last-child * {
  fill: #aaec2e;
  stroke: #aaec2e;
}
.hotbar > .box > [onsw-hotornot-button][type="hot"] > .box  {
  background-color: #aaec2e;
  border: 2px solid #aaec2e;
  padding: 6px;
}
[onsw-hotornot-button][type="hot"] > .box > .icon * {
  fill: #fff;
  stroke: #fff;
}
[onsw-hotornot-button][type="hot"] > .box:hover {
  border: 2px solid #aaec2e;
  background-color: white !important;
}
[onsw-hotornot-button][type="hot"] > .box:hover > .icon * {
  stroke: #aaec2e;
  fill: #aaec2e;
}
[onsw-hotornot-button][type="not"] > .box > .icon * {
  fill: #fc0000;
}
.hotbar > .box > [onsw-hotornot-button][type="not"] > .box:hover {
  border: 2px solid #fc0000;
  padding: 6px;
}

.hotornot-positive > .box  {
  background: white;
  border: 2px solid white;
  border-radius: var(--default-border-radius);
  transition: transform .2s ease;
}

[onsw-hotornot] .timer {
  border-radius: 5px;
  background: linear-gradient(0deg, #fff, transparent);
  min-width: 300px;
}
[onsw-hotornot] .timer > .box {
  background: linear-gradient(0deg, #0005, #0000);
  border-radius: var(--default-border-radius);
}

@media (min-width: 768px) {
  [onsw-text].hotornot.descr {
    color: #3a5093;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .hotornot-pending {
    border: none;
    border-radius: 0;
  }

  .online.hotornot {
    top: auto;
    bottom: 188px;
  }
}

.ons-text.frame {
  margin-bottom: 10px;
}

.ons-text.emptyList {
  margin: 10px 0;
  font-weight: 600;
  text-align: center;
}
.ons-text.emptyList > .box > .text::before {
  display: block;
  content: '';
  background-image: url(../img/noResult.png);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  height: 150px;
}

.ons-text.livecamLoading > .box {
  color: #fff;
}


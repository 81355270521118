/* amx */
@import '../css/messenger/voicemessage.css';
@import '../css/messenger/voicemessageRecorder.css';

.layout-toolbar.messenger-voice {
  --voicemessage-msg-out-wave-color: white;
  --voicemessage-msg-out-wave-progress-color: #b9d5f9;
  --voicemessage-msg-out-btn-color: #b9d5f9;
  --voicemessage-msg-out-btn-color-hover: white;
  --voicemessage-msg-out-btn-bg-active: white;
  --voicemessage-msg-out-btn-color-active: #5295f2;
  --voicemessage-msg-out-duration-color: #ffffffb3;
  --voicemessage-msg-in-wave-color: #1d2129;
  --voicemessage-msg-in-wave-progress-color: #5295f2;
  --voicemessage-msg-in-btn-color: #385898;
  --voicemessage-msg-in-btn-color-hover: #5295f2;
  --voicemessage-msg-in-btn-bg-active: #5295f2;
  --voicemessage-msg-in-btn-color-active: white;
  --voicemessage-msg-in-duration-color: #a1a1a1;
}

.messenger-voice-recorder {
  --record-btn-border-radius: 50%;
  --record-btn-bg: #385898;
  --record-btn-border: 1px solid #385898;
  --record-btn-hover-bg: var(--default-btn-hover-bg);
  --voicemessage-recorder-wave-color: #1d2129;
  --voicemessage-recorder-wave-progress-color: #5295f2;
  --voicemessage-recorder-btn-color: #385898;
  --voicemessage-recorder-btn-color-hover: #5295f2;
  --voicemessage-recorder-btn-bg-active: #5295f2;
  --voicemessage-recorder-btn-color-active: white;
  --voicemessage-recorder-text-color: #a1a1a1;
}

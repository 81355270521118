[onsw-stock-indicator].picture.invalid > .box {
  overflow: hidden;
}

[onsw-stock-indicator].picture.invalid > .box > .label {
  height: 28px;
  font-size: 10px;
  text-align: center;
  line-height: 28px;
  margin: auto;
  transform: rotate(-20deg) scale(1.5);
  color: white;
  opacity: .7;
  background: #5295f2;
}

[onsw-stock-indicator].movieDetail > .box > .value > .icon,
[onsw-stock-indicator].picture > .box > .value > .icon {
  filter: drop-shadow(0 1px 1px rgba(0,0,0,.4));
}

[onsw-stock-indicator].movieDetail > .box > .value,
[onsw-stock-indicator].picture > .box > .value {
  background: rgba(255,255,255,0.7);
  padding: 1px 4px;
  border-radius: 3px 0 0 0;
  right: 0;
  bottom: 0;
}

[onsw-stock-indicator].picture > .box > .icon {
  bottom: 2px;
}

[onsw-stock-indicator].valid > .box > .icon * {
  fill: #42b72a;
}
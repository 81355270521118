.livecamRegardOverlay {
  box-shadow: 0 0 90px rgba(0,0,0,.3);
}
.livecamRegardOverlay .ons-heading {
  font-size: 18px;
  font-weight: bold;
  color: #385898;
}
.livecamRegardOverlay .ons-heading > span {
  color: #5295f2;
  font-size: 36px;
}

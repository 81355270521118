:root {
  --warp-gap-default: 4px;

  .myportal2-hook {
    --warp-gap: 4px;
  }

  --background: #fff;
  --highlight-color: #385898;
  --highlight-color-active: #5295f2;

  --headline: Arial, "Helvetica Neue", Helvetica, sans-serif;
  --default-font: Arial, "Helvetica Neue", Helvetica, sans-serif;

  --primary-color: #5295f2;
  --text-strong: 600;
  --default-border: 1px solid #dddfe2;
  --messenger-border-color: #dddfe2;
  --default-border-radius: 3px;

  --layout-overlay-box-shadow: 0 0 15px rgba(0,0,0,.5);

  --panel-heading-font-large: var(--text-strong) 21px var(--headline);

  --freemovie-logo: url('../img/fmotd/logo.png');

  --menu-bg: #e9ebee;
  --menu-item-bg-hover: white;
  --menu-item-bg-active: #5295f2;
  --menu-hook-width: 75px;
  --menu-boxShadow: 3px 3px 10px #00000080;
  --menu-box-padding: 10px 10px 80px;
  --menu-spacer: -3px; /* "display: none" für arme */
  --menu-item-padding: 14px;
  --menu-item-margin-bottom: 6px;
  --menu-item-border-width: 1px;
  --menu-item-border-color: white;
  --menu-item-border-color-hover: #dddfe2;
  --menu-item-border-color-active: #385898;
  --menu-item-border-radius: 3px;
  --menu-item-label-color: #385898;
  --menu-item-label-color-hover: #385898;
  --menu-item-label-color-active: white;
  --menu-item-label-font: 600 15px / 1 var(--headline);
  --menu-item-icon-color: #385898;
  --menu-item-icon-color-hover: #385898;
  --menu-item-icon-color-active: white;
  --menu-item-open-icon-color-hover: #5295f2;
  --menu-item-open-icon-color-active: #5295f2;
  --menu-item-signup-bg: white;
  --menu-item-signup-bg-hover: white;
  --menu-item-signup-label-color: #42b72a;
  --menu-item-signup-icon-color: #42b72a;
  --menu-item-signup-icon-hover: #385898;
  --has-submenu-bg: var(--submenu-bg);
  --submenu-bg: white;
  --submenu-item-bg-hover: white;
  --submenu-item-bg-active: white;
  --submenu-item-padding-bottom: 3px;
  --submenu-item-height: 36px;
  --submenu-item-font: 600 14px / 1 var(--headline);
  --submenu-item-label-color: #385898;
  --submenu-item-label-color-hover: #5295f2;
  --submenu-item-label-color-active: #5295f2;
  --submenu-item-border-color: #dddfe2;

  --accordeon-btn-bg: white;
  --accordeon-btn-color: #385898;
  --accordeon-btn-padding: 0;
  --accordeon-btn-font: var(--text-strong) 15px / 22px var(--headline);
  --accordeon-btn-hover-color: #5295f2;
  --accordeon-btn-active-color: #5295f2;
  --accordeon-btn-sexicons-bg: var(--accordeon-btn-bg);
  --sexicon-list-padding: 10px 0 0;
  --sexicon-img-width: calc(232px / 5);

  --alternative-product-title-font: var(--panel-heading-font);
  --alternative-product-description-font: 15px var(--default-font);
  --alternative-product-logo-background: #444;

  --personal-info-badge-icon-color: #385898;
  --personal-info-badge-label-font: 12px var(--default-font);
  --personal-info-badge-value-font: 600 12px var(--default-font);
  --personal-info-badge-border: var(--default-border);
  --personal-info-badge-border-radius: var(--default-border-radius);
  --personal-info-list-label-font: 600 12px var(--default-font);
  --personal-info-list-value-font: 12px var(--default-font);
  --personal-info-list-border-color: #dddfe2;

  --input-font: 13px var(--default-font);
  --input-bg: #edf4fe;
  --input-color: #1d2129;
  --input-border-radius: var(--default-border-radius);
  --input-textarea-border-radius: var(--default-border-radius);
  --input-border: var(--default-border);
  --input-bg-focus: white;
  --input-color-focus: var(--input-color);
  --input-border-focus: 1px solid var(--primary-color);
  --input-boxshadow-focus: 0 0 5px #385898;

  --messenger-form-gap: 6px;
  --messenger-input-bg: white;
  --messenger-input-border: var(--input-border);
  --messenger-input-color-focus: var(--input-color);
  --messenger-input-border-focus: var(--input-border-focus);
  --messenger-input-boxshadow-focus: var(--input-boxshadow-focus);

  --button-border-radius: var(--default-border-radius);
  --default-btn-padding: 8.5px 8px;
  --default-btn-height: 34px;
  --default-btn-font: 600 13px/15px var(--default-font);
  --default-btn-text-color: white;
  --default-btn-text-transform: unset;
  --default-btn-text-shadow: unset;
  --default-btn-bg: var(--primary-color);
  --default-btn-border: 1px solid #243963;
  --default-btn-hover-bg: #243963;

  --send-btn-bg: var(--default-btn-bg);
  --send-btn-border: var(--default-btn-border);
  --send-btn-hover-bg: var(--default-btn-hover-bg);
  --send-btn-border-radius: var(--button-border-radius);
  --send-btn-icon-color: white;

  --link-color: #5295f2;

  --amateur-topic-badge-fg: white;
  --amateur-topic-badge-bg: #5295f2;

  --overlay-reason-bg: #5295f2;

  --sedcard-online-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  --sedcard-online-font: 600 13px var(--default-font);

  --sedcard-button-height: 50px;
  --sedcard-button-padding: 0 8px;
  --sedcard-button-bg: white;
  --sedcard-button-bg-hover: #5295f2;
  --sedcard-button-border: var(--default-border);
  --sedcard-button-border-hover: 1px solid #243963;
  --sedcard-button-text-size: initial;
  --sedcard-button-text-color: #1d2129;
  --sedcard-button-text-color-hover: white;
  --sedcard-button-attachment-icon: initial;
  --sedcard-button-hover-attachment-icon: white;
  --sedcard-button-chatColor1: #c6de86;
  --sedcard-button-chatColor2: #ffe0b2;

  --dialog-buttons-box-gap: 6px;
  --dialog-buttons-box-padding: 10px 8px;
  --dialog-button-height: 32px;
  --dialog-button-padding: 0 3px;
  --dialog-button-bg: #e6e6e6;
  --dialog-button-bg-hover: #5295f2;
  --dialog-button-border: 1px solid white;
  --dialog-button-border-hover: 1px solid #243963;
  --dialog-button-text-size: 12px;
  --dialog-button-text-color: #385898;
  --dialog-button-text-color-hover: white;
  --dialog-button-attachment-icon: initial;
  --dialog-button-hover-attachment-icon: white;

  --icon-offline-color: rgba(180,180,180,0.8);
  --icon-online-color: #42b72a;

  --default-close-color: #385898;
  --default-close-color-hover: #5295f2;

  --contact-bg: white;
  --contact-margin: 7px;
  --contact-img-border-radius: 50%;
  --contact-border-radius: var(--default-border-radius);
  --contact-background-hover: white;
  --contact-background-active: white;
  --contact-border: 1px;
  --contact-border-active: 2px;
  --contact-border-active-color: #5295f2;
  --contact-background-flash-from: white;
  --contact-background-flash-to: white;
  --contact-nickname-font-size: 14px;
  --contact-nickname-color: #385898;
  --contact-nickname-font-weight: 600;
  --contact-nickname-color-hover: #5295f2;
  --contact-nickname-color-active: #5295f2;
  --contact-close-color: #385898;
  --contact-close-color-hover: #5295f2;
  --contact-icon-color: unset;
  --contact-timestamp-color: #a1a1a1;

  --contact-new-color: #5295f2;
  --contact-new-bg: transparent;
  --contact-new-padding: 0 5px;
  --contact-new-bg-border-radius: var(--default-border-radius);
  --contact-new-flash-bg-from: rgba(82,149,242,0);
  --contact-new-flash-bg-to: rgba(82,149,242,1);
  --contact-new-flash-color-from: rgb(82,149,242);
  --contact-new-flash-color-to: rgb(255,255,255);
  --contact-new-font-weight: 600;
  --contact-new-text-transform: uppercase;

  --livecam-icon-normal: #42b72a;
  --livecam-icon-voyeur: #385898;
  --livecam-icon-exclusive: #ffcf70;
  --livecam-icon-sedcard-image-normal: #5295f2;
  --livecam-icon-sedcard-image-voyeur: var(--livecam-icon-voyeur);
  --livecam-icon-sedcard-image-exclusive: var(--livecam-icon-exclusive);

  --livecam-button-normal-bg: #5295f2;
  --livecam-button-normal-color: white;
  --livecam-button-normal-label-text-shadow: unset;
  --livecam-button-normal-border: 1px solid #5295f2;
  --livecam-button-normal-box-shadow: unset;
  --livecam-button-normal-hover-bg: #385898;
  --livecam-button-normal-hover-color: white;
  --livecam-button-normal-hover-label-text-shadow: unset;
  --livecam-button-normal-hover-border: 1px solid #385898;

  --livecam-button-exclusive-bg: #ffcf70;
  --livecam-button-exclusive-color: #1d2129;
  --livecam-button-exclusive-label-text-shadow: unset;
  --livecam-button-exclusive-border: 1px solid #ffcf70;
  --livecam-button-exclusive-box-shadow: unset;
  --livecam-button-exclusive-hover-bg: #fdd483;
  --livecam-button-exclusive-hover-color: #1d2129;
  --livecam-button-exclusive-hover-label-text-shadow: unset;
  --livecam-button-exclusive-hover-border: 1px solid #fdd483;

  --livecam-button-voyeur-bg: #385898;
  --livecam-button-voyeur-color: white;
  --livecam-button-voyeur-label-text-shadow: unset;
  --livecam-button-voyeur-border: 1px solid #385898;
  --livecam-button-voyeur-box-shadow: unset;
  --livecam-button-voyeur-hover-bg: #5295f2;
  --livecam-button-voyeur-hover-color: white;
  --livecam-button-voyeur-hover-label-text-shadow: unset;
  --livecam-button-voyeur-hover-border: 1px solid #5295f2;

  --livecam-button-label-font-size: 15px;
  --livecam-button-additional-font-size: 13px;
  --livecam-button-box-shadow: 1px 1px 2px rgba(0,0,0,.4);

  --livecam-show-close-bg: rgba(0,0,0,.6);
  --livecam-show-close-bg-hover: #5295f2;
  --livecam-show-close-icon: #385898;
  --livecam-show-close-icon-hover: white;

  --livecam-show-button-bar-bg: rgba(0,0,0,.4);
  --livecam-show-input-border: var(--default-border);

  --livecam-show-message-bg: rgba(0,0,0,.4);
  --livecam-show-message-border-radius: var(--default-border-radius);

  --livecam-show-button-bg: white;
  --livecam-show-button-color: #385898;
  --livecam-show-button-border: 1px solid white;
  --livecam-show-button-border-radius: var(--default-border-radius);
  --livecam-show-button-hover-bg: #5295f2;
  --livecam-show-button-hover-color: white;
  --livecam-show-button-hover-border: 1px solid #5295f2;

  --livecam-show-send-button-bg: #5295f2;
  --livecam-show-send-button-color: white;
  --livecam-show-send-button-border: 1px solid #243963;
  --livecam-show-send-button-box-shadow: var(--livecam-button-box-shadow);
  --livecam-show-send-button-hover-bg: #243963;
  --livecam-show-send-button-hover-color: white;
  --livecam-show-send-button-hover-border: 1px solid #243963;

  --livecam-show-vibration-button-font-weight: 600;
  --livecam-show-vibration-button-font-size: 18px;
  --livecam-show-queue-vibration-button-font-size: 16px;

  --livecam-show-smiley-select-bg: #e9ebee;
  --livecam-show-smiley-select-border-radius: var(--default-border-radius);

  --livecam-show-smiley-button-hover-color: white;

  --livecam-show-rebuy-seconds: #5295f2;

  --livecam-motto: 600 16px/1 Arial, "Helvetica Neue", Helvetica, sans-serif;

  --hotornot-teaser-logo-size: 50%;
  --hotornot-teaser-bg-url: url(../img/hotornot/bgTeaser.jpg);
  --hotornot-teaser-new-url: url(../img/hotornot/findout.png);
  --hotornot-teaser-new-url-en: url(../img/hotornot/findout_en.png);
  --hotornot-teaser-logo: url(../img/hotornot/logo.png);
  --hotornot-teaser-logo-en: url(../img/hotornot/logo_en.png);
  --hotornot-teaser-imgBox-border: 2px solid white;
  --hotornot-teaser-imgBox-boxshadow: 5px 5px 8px -2px rgba(0, 0, 0, 53%);
  --hotornot-teaser-button-bg: linear-gradient(to bottom, rgb(182, 224, 38) 0%, rgb(171, 220, 40) 100%);
  --hotornot-teaser-button-text-shadow: 0 1px 3px #236526;
  --hotornot-teaser-button-border: 1px solid #aaec2e;
  --hotornot-teaser-button-boxshadow: var(--hotornot-teaser-imgBox-boxshadow);
  --hotornot-teaser-button-hover-bg: #5295f2;
  --hotornot-teaser-button-hover-border: 1px solid #385898;

  --portal-widget-box-bg: #e9ebee;
  --portal-widget-padding: 0;
  --portal-widget-bg: transparent;

  --portal-heading-box-bg:
      linear-gradient(to right, #e9ebee 0, transparent 5%),
      linear-gradient(to left, #e9ebee 0, transparent 20%),
      linear-gradient(to bottom, #f5f5f5 10%, transparent 100%);
  --portal-heading-box-border-color: #ff7300;
  --portal-heading-box-padding: 5px 10px;
  --portal-heading-box-color: #385898;
  --portal-heading-box-font-family: "PT Sans Narrow", sans-serif;
  --portal-heading-box-font-weight: 700;
  --portal-heading-box-icon-color: #5295f2;

  --portal-scroll-bg: white;
  --portal-scroll-icon-color: #385898;
  --portal-scroll-icon-hover-color: #5295f2;

  --portal-small-box-title-font-size: 14px;
  --portal-small-box-title-color: #385898;
  --portal-small-box-title-font-weight: 600;

  --floating-menu-bg: #5295f2;
  --floating-menu-icon: white;

  --countdown-default-box-shadow: 2px 2px 6px rgba(0, 0, 0, .7);
  --countdown-default-border: unset;
  --countdown-default-bg: #5295f2;
  --countdown-default-color: white;
  --countdown-default-font-weight: 700;
  --countdown-default-font-size: 25px;
  --countdown-default-1024-font-size: 30px;
  --countdown-default-text-shadow: 0 0 2px #385898;

  --countdown-sidebar-top-position: 400px;
  --countdown-sidebar-bottom-position: unset;

  --countdown-wellcum-box-shadow: 2px 2px 6px rgba(0, 0, 0, .7);
  --countdown-wellcum-border: unset;
  --countdown-wellcum-bg: #5295f2;
  --countdown-wellcum-color: white;
  --countdown-wellcum-text-shadow: 0 0 2px #385898;

  --countdown-sidebar-wellcum-top-position: unset;
  --countdown-sidebar-wellcum-bottom-position: 20px;
  --countdown-sidebar-font-size: 30px;

  --doi-hint-text-color: #5495f0;
  --doi-hint-text-font-weight: 600;
  --checkmark-url: url("../../basic/img/checkmark.png");

  --unsubscribe-image-url: url('../../amx/img/unsubscribe.jpg');

  --achievement-heading-font: 600 14px var(--default-font);

  --gameOverSplash: #5495f0;
  --gameOverShadow: .5;
  --gameOverGradientColor1: #EF7D00;
  --gameOverGradientColor2: #F08500;
  --gameOverGradientColor3: #F5AA00;
  --gameOverGradientColor4: #F9C700;
  --gameOverGradientColor5: #FCDC00;
  --gameOverGradientColor6: #FEE800;
  --gameOverGradientColor7: #FFED00;

  --game-default-gap: 10px;
  --game-headline: 600 15px Arial, "Helvetica Neue", Helvetica, sans-serif;
  --games-timer-background: #e9ebee;
  --games-timer-bar-background: linear-gradient(to right, #c80000 0%, #42b72a 80px);
  --games-timer-color: white;

  --game-session-info-bg: #e9ebee;
  --game-session-hover-bg: white;

  --games-session-info-bg: white;
  --games-session-hover-bg: #e9ebee;
  --games-session-text-transform: none;
  --games-session-text-color: #385898;
  --games-session-info-font-color: #1d2129;
  --games-session-win-color: #42b72a;
  --games-session-lose-color: #c80000;
  --games-session-draw-color: #5495f0;

  --game-icon-default: #385898;
  --game-icon-userTurn: #c80000;
  --game-icon-amateurTurn: #ffd700;
  --game-icon-isPending: #ffcf70;

  --gameColorBg: #ffcf70;
  --gameColorShadow: rgba(0,0,0,.1);
  --gameColorPlus: #c80000;
  --gameColorElements: #5295f2;

  --game-wrapper: white;

  --game-img-border-radius: 50%;
  --game-img-name-color: #5295f2;

  --game-inviteBox-bg: #e9ebee;
  --game-inviteBox-border-color: #5295f2;
  --game-inviteBox-bg-hover-text-color: white;

  --game-suggestions-bg: var(--game-wrapper);
  --game-suggestions-text-color: #1d2129;
  --game-suggestions-name-color: #385898;
  --game-suggestions-hover-color: #5295f2;

  --games-info-background: #385898;
  --games-info-icon: white;
  --games-info-hover-background: #5295f2;
  --game-inviteBox-sedcard-bg-hover-label-color: #385898;

  --rules-color: #5295f2;
}

onsw-comment-list {
  --comment-gap: 5px;
  --comment-padding: 5px;
  --comment-border: var(--default-border);
  --comment-border-width: 1px;
}

onsw-game {
  connect-four-game {
    width: 100%;
    --game-wrapper: white;
    --game-background: #e9ebee;
    --game-border: 1px solid white;
    --value1-background:radial-gradient(ellipse at center,#f00000 52%, #c80000 55%, #f00000 60%);
    --value2-background:radial-gradient(ellipse at center,#ffd700 52%, #dcb900 55%, #ffd700 60%);
    --value0-box-shadow: inset 0 0 6px 0 #b7b7b7, 0 1px 1px #c2c2c2;
    --value1-box-shadow: inset 0 0 2px #b7b7b7;
    --value2-box-shadow: inset 0 0 2px #b7b7b7;
    --column-hover-background: #edf4fe;
  }
}

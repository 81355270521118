[onsw-amateur-common-filter] > .box > div.suggestions {
  background-color: white;
}

[onsw-amateur-common-filter] > .box > div.suggestions .suggestion.selected {
  background: #edf4fe;
}

[onsw-amateur-common-filter] > .box > div.suggestions .label {
  font-size: 13px;
  color: #333;
}

.ons-layout.overlay-content {
  background: white;
  border-radius: 0 0 3px 3px;
}

@media (min-width: 1024px) {
  .ons-layout.overlay > .box.default-overlay > .overlay-content,
  .ons-layout.overlay-content.doiHint,
  .box.withBonus > .ons-layout.overlay-content.payment {
    border-radius: var(--default-border-radius);
  }
}

@media (min-width: 1024px) {
  .ons-layout.overlay > .box.withBonus > .overlay-content.payment {
    padding-top: 15px;
  }
}

.ons-layout.overlay.fixed-main {
  background: rgba(0, 0, 0, .3);
}


/**
 * DOI-HINT
 */
:lang(de).getsFreeCoinsForDoi{
  background-image: url(../../img/freecoins/doi_hint_overlay_de.jpg) !important;
}
:lang(en).getsFreeCoinsForDoi{
  background-image: url(../../img/freecoins/doi_hint_overlay_en.jpg) !important;
}


/**
 * PAYMENT
 */
@media (min-width: 1024px) {
  :lang(de).overlay > .payment-box > .overlay-content.showFreeCoinsBanner > .box {
    background-image: url(../../img/freecoins/payment_overlay_header_de.jpg) !important;
  }

  :lang(en).overlay > .payment-box > .overlay-content.showFreeCoinsBanner > .box {
    background-image: url(../../img/freecoins/payment_overlay_header_en.jpg) !important;
  }
}

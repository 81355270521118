.ons-image[click-action] > .box,
.ons-image[image-click] > .box {
  cursor: pointer;
}

.ons-image.noPointer > .box {
  cursor: unset;
}

.ons-image > .box {
  padding-top: 75%;
}
.overlay-content .ons-image > .box {
  padding-top: 100%;
}

.ons-image.done > .box,
.ons-image.error > .box {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ons-image.busy > .box {
  background: url('../basic/img/loading2.svg') center no-repeat !important;
}

.ons-image.wide > .box {
  padding-top: 56%;
}

.ons-image.square > .box {
  padding-top: 100%;
}

/**
 * Livecam Loading Image
 */
.ons-image.livecamLoading {
  height: 100%;
}

.ons-image.livecamLoading > .box {
  height: 100%;
  width: 100%;
}

/**
 * MESSENGER DIALOG
 */
.ons-image.messengerDialog {
  float: left;
  padding: 10px;
  pointer-events: none;
}

.ons-image.messengerDialog > .box {
  display: block;
  padding-top: unset;
  object-fit: cover;
  width: 108px;
}

@media (max-height: 450px) {
  .ons-image.messengerDialog {
    display: none;
  }
}

/**
 * global feed
 */
.ons-image.small-feed-widget {
  float: left;
  margin-right: 10px;
}

.ons-image.small-feed-widget > .box {
  height: 70px;
  width: 70px;
}

/**
 * PICTURE SLIDESHOW
 */
.ons-image.pictureSlideshow > .box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ons-image.pictureSlideshow.done > .box {
  padding-top: unset;
  background-size: contain;
}

/**
 * PAYMENT BADGES
 */

[hookname='paymentBadges'] > .box {
  border-top: 1px solid;
  background: white;
  text-align: center;
  overflow: hidden;
}
.ons-image.paymentBadges > .box {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: unset;
  background-color: transparent !important;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ons-image.paymentBadges {
  display: inline-block;
  vertical-align: middle;
  padding: 0 6px;
}

.ons-image.paymentBadges:nth-child(1) {
  width: 46px;
  height: 50px;
}

.ons-image.paymentBadges:nth-child(2) {
  width: 67px;
  height: 50px;
}

.ons-image.paymentBadges:nth-child(3) {
  width: 46px;
  height: 50px;
}

@media (min-width: 1024px) {
  .ons-image.paymentBadges:nth-child(1) {
    width: 57px;
    height: 75px;
  }

  .ons-image.paymentBadges:nth-child(2) {
    width: 92px;
    height: 75px;
  }

  .ons-image.paymentBadges:nth-child(3) {
    width: 57px;
    height: 75px;
  }
}

/** ## Messenger (amx) */
.layout-container.messenger,
.layout-container.regard-shop,
.layout-container.regard-shop > .layout-overlay {
  /** ### Layout */
  --container-gap: 0;
  --container-background: #e9ebee;
  --messenger-border-color: #dddfe2;
  --messenger-contacts-gap: 4px;
  --messenger-tab-bg-active: #5295f2;
  --messenger-scroll-bg: #e9ebee;
  --messenger-scroll-icon-color: #5295f2;

  .layout-panel {
    border: none;
  }

  .layout-list {
    --list-gap: 3px;
    --list-padding: 0 7px;
  }

  .layout-field {
    --input-gap: 10px;
    --input-label-font: var(--panel-subheading-font);
    --input-label-color: var(--panel-subheading-color);
  }

  .layout-input {
    --input-bg: white;
  }

  .layout-item {
    --item-margin: 1px;
    --item-outline-offset: -1px;
  }

  /** ### Regard */
  --regard-shop-border: var(--default-border);
  --regard-list-min-width: 150px;

  .layout-link.regard-category {
    --regard-link-color-active: #5295f2;
    --link-margin: 1px;
    --link-outline-offset: -1px;
  }
}

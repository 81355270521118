.layout-toolbar.toolbar-list.livecam-tile-widget { 
  font-size: 12px;
}

.livecam-tile-box .ons-item.tile {
  background: rgba(255,255,255,0.7);
}

.livecam-tile-box .ons-item.tile > .box {
  filter: drop-shadow(2px 2px 2px white);
  color: #385898;
}

.livecam-tile-widget[onsw-livecam-button] > .box > .icon * {
  fill: #385898;
}

.livecam-tile-box .ons-item.tile > .box .additional,
.livecam-tile-box .ons-item.tile > .box .label {
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
}

.livecam-tile-box .ons-item.tile > .box .additional {
  font-weight: 400;
}

figcaption.livecam-tile-widget > .box:hover {
  color: #5295f2;
}


.ons-layout.overlay-toolbar > .box {
  background: white;
  border-radius: 3px 3px 0 0;
}

.ons-layout.overlay-toolbar > .box > .ons-heading.default > .box {
  margin-bottom: 0 !important;
}

.ons-layout.overlay-toolbar.bonusOverlay > .box {
  padding: 0;
}
.ons-layout.overlay-toolbar.bonusOverlay > .box > .ons-item {
  margin: 10px;
}

.ons-layout.overlay-toolbar > .box > .ons-item[click-action] > .box {
  font-size: 15px;
  font-weight: 600;
  color: #385898;
  line-height: inherit;
}
.ons-layout.overlay-toolbar.bonusOverlay > .box > .ons-item[click-action] > .box {
  padding: 5px;
}

.ons-layout.overlay-toolbar > .box > .ons-item[click-action] > .box > .icon * {
  fill: #385898;
}
.ons-layout.overlay-toolbar > .box > .ons-item.active > .box,
.ons-layout.overlay-toolbar > .box > .ons-item[click-action] > .box:hover {
  color: #5295f2;
}

.ons-layout.overlay-toolbar > .box > .ons-item.active > .box > .icon *,
.ons-layout.overlay-toolbar > .box > .ons-item[click-action] > .box:hover > .icon * {
  fill: #5295f2;
}

@media (min-width: 1024px) {
  .default-overlay > .ons-layout.overlay-toolbar:not(.livecamRegardOverlay) > .box > .ons-item[click-action] > .box {
    padding: 15px;
  }
}

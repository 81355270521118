.ons-item.link > .box,
onsw-amateur-tag-list a,
.content a,
.overlay-content a {
  color: #5295f2;
  text-decoration: underline;
}

.ons-item.link > .box:hover,
onsw-amateur-tag-list a:hover,
.content a:hover {
  color: #385898;
}

.out > p.msg > .ons-item.link > .box {
  color: #fff; 
}

.out > p.msg > .ons-item.link > .box > .icon * {
  fill: #fff;
}

.pageLogin .ons-item.link > .box {
  color: #1d2129;
}

.pageLogin .ons-item.link.signupLong  > .box{
  color: #5295f2;
}

onsw-messenger-message-box > .box > .msg > .box {
  position: relative;
  display: inline-block;
  min-width: 60%;
  max-width: 85%;
  padding: 0.75em;
  margin: 0;
  border-radius: var(--default-border-radius);
  transition: all .3s ease-out;
}

onsw-messenger-message-box > .box > .msg.in > .box {
  background: white;
  color: #1d2129;
  border-radius: var(--default-border-radius);
  filter: drop-shadow(0 0 1px #dddfe2);
}

onsw-messenger-message-box > .box > .msg.in > .box::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 11px 11px 11px 0;
  border-color: transparent white transparent transparent;
  top: 9px;
  left: -11px;
}

onsw-messenger-message-box > .box > .msg.out > .box {
  color: white;
  background: #5295f2;
  border-radius: var(--default-border-radius);
  text-align: left;
  filter: drop-shadow(0 0 1px #243963);
}

onsw-messenger-message-box > .box > .msg.out > .box::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 11px 0 11px 11px;
  border-color: transparent transparent transparent #5295f2;
  top: 9px;
  right: -11px;
}

onsw-messenger-message-box.ready > .box > .msg > .box,
onsw-messenger-message-box.ready > .box > .msg > .box::after {
  transition: all .3s ease-out;
}

onsw-messenger-message-box > .box > .msg.ng-enter-active > .box {
  background-color: #385898;
  color: white;
}

onsw-messenger-message-box > .box > .msg.out.ng-enter-active > .box::after {
  border-left-color: #385898;
}

onsw-messenger-message-box > .box > .msg.in.ng-enter-active > .box::after {
  border-right-color: #385898;
}


onsw-messenger-message-box > .box > .msg.regard > .box {
  font-weight: 400;
}

.flying-chat-box {
  background: #385898 !important;
  color: white;
  border-radius: var(--default-border-radius);
}



/*onsw-messenger-message-box > .box > .msg.in > .box::before {*/
/*  content: "";*/
/*  width: 0;*/
/*  height: 0;*/
/*  position: absolute;*/
/*  border-style: solid;*/
/*  border-width: 12px 12px 12px 0;*/
/*  border-color: transparent #dddfe2 transparent transparent;*/
/*  top: 8px;*/
/*  left: -12px;*/
/*}*/


/*onsw-messenger-message-box > .box > .msg.out > .box::before {*/
/*  content: "";*/
/*  width: 0;*/
/*  height: 0;*/
/*  position: absolute;*/
/*  border-style: solid;*/
/*  border-width: 12px 0 12px 12px;*/
/*  border-color: transparent transparent transparent #243963;*/
/*  top: 8px;*/
/*  right: -12px;*/
/*}*/


onsw-messenger-message-box > .box > .msg > .meta {
  font-size: 11px;
  margin-top: 5px;
  color: #a1a1a1;
}

[onsw-amateur-label].messengerDialog > .box:hover {
  background: white;
}

onsw-messenger-message-box .out .ons-item.link > .box > .icon * {
  fill: white;
}
onsw-messenger-message-box .out .ons-item.link > .box:hover > .label {
  text-decoration: underline;
}
onsw-messenger-message-box .out .ons-item.link > .box {
  color: white;
}

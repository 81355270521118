.fmotd-teaser-box,
.fmotd-teaser-hook .top {
  background: white;
}

.fmotd-teaser-hook .layout-toolbar.toolbar-list {
  background: linear-gradient(to bottom, transparent, white 70%) !important;
}

.fmotd-teaser-hook .layout-toolbar.toolbar-list > .box > [onsw-item].add > .box > .label {
  color: #5295f2;
}
.fmotd-teaser-hook .current .layout-toolbar.toolbar-list > .box > [onsw-item] > .box > .label {
  color: #5295f2;
}

.ons-image.messengerDialog {
  padding: 10px;
}

.ons-image.messengerDialog > .box {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

[onsw-amateur-label].messengerDialog > .box {
  background: white;
  border: var(--default-border);
  border-radius: var(--default-border-radius);
  padding: 10px;
}

[onsw-amateur-label].messengerDialog > .box > .name {
  color: #385898;
  font-size: 15px;
}

[onsw-amateur-label].messengerDialog > .box > .age,
[onsw-amateur-label].messengerDialog > .box > .from {
  font-size: 12px;
}

[onsw-amateur-label].messengerDialog > .box > .goto {
  color: #5295f2;
  line-height: 25px;
  padding-left: unset;
  font-weight: 600;
}
[onsw-amateur-label].messengerDialog > .box:hover > .goto {
  color: #385898;
}

@media (max-width: 767px) {

  .layout-container.messenger {
    > .tabs > .box > .ons-item {
      z-index: 2;
    }

    > .messenger-dialog.short {
      grid-row: 1 / 4;
    }
  }

  .messenger-dialog.short > :nth-child(1) {
    text-align: center;
  }

  .messenger-dialog.short > :nth-child(1),
  .messenger-dialog > :nth-child(2) {
    background-color: white;
  }

  .messenger-dialog.short .ons-image.messengerDialog {
    float: none;
  }
  .messenger-dialog.short .ons-image.messengerDialog > .box {
    height: 70px;
    width: 70px;
    border-radius: 50%;
  }
  .messenger-dialog.short [onsw-amateur-label].messengerDialog,
  .messenger-dialog.short [onsw-amateur-label].messengerDialog > .box {
    height: unset;
  }
  .messenger-dialog.short [onsw-amateur-label].messengerDialog > .box {
    border: none;
  }
  .messenger-dialog.short [onsw-amateur-label].messengerDialog > .box > .goto {
    display: none;
  }
  onsw-messenger-message-box > .box > .msg.in > .box {
    background: #e9ebee;
  }
  onsw-messenger-message-box > .box > .msg.in > .box::after {
    border-right-color: #e9ebee;
  }

  .messenger-dialog:not(.short) > .ons-layout > .box > [onsw-amateur-label] > .box {
    height: unset;
    background: unset;
    border: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .messenger-dialog:not(.short) > .ons-layout > .box > [onsw-amateur-label] > .box > .from {
    display: inline;
  }
  .messenger-dialog:not(.short) > .ons-layout > .box > [onsw-amateur-label] > .box > .goto {
    display: none;
  }
  .messenger-dialog:not(.short) > .ons-layout > .box > .ons-image > .box {
    height: 36px;
    width: 36px;
    padding-top: unset;
    border-radius: 50%;
  }
}


.messenger-dialog > onsw-message-composer.layout-panel {
  background: var(--container-background);
  padding: 10px 8px 0;
}

body {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #1d2129;
  background: #e9ebee;
}

.ons-layout.fixed-main:not(.overlay) > .box {
  background: #e9ebee;
}

@media (min-width: 768px) {
  .ons-layout.fixed-main:not(.overlay) > .box {
    padding: 10px 5px;
  }

  .ons-layout.fixed-main:not(.overlay) > .box:not(.livecam-show-box) {
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .ons-layout.fixed-main:not(.overlay) > .box {
    padding: 10px 5px 0;
  }
}

@media (max-width: 1280px) {
  body.show-dialog .main-box {
    padding: 10px 10px 10px 5px !important;
  }
}

.layout-container.messenger > .box {
  background: #e9ebee;

  > .items > .scroll-overlay {
    > .scroll-top,
    > .page-down {
      width: unset;
      left: 0;
      right: 0;
      border: none;
      background: #e9ebee;
      z-index: 2;
    }

    > span > .icon * {
      fill: #5295f2;
    }
  }
}

@media (min-width: 768px) {
  .ons-scroll[hookname="main"] > .scroll-overlay > .bar {
    right: 0;
    width: 6px;
  }
}

@media (min-width: 768px) {
  .ons-layout.fixed-main {
    left: 75px;
  }
}

.ons-scroll > .scroll-overlay > .bar > .slider {
  background-color: #dddfe2;
}

/* dropdown */
.ons-layout.dropdown > .box {
  background: white;
  color: #1d2129;
  border: var(--default-border);
  border-radius: var(--default-border-radius);
  z-index: 1;
}

.ons-layout.dropdown > .box > .spacer{
  border-top: 2px solid #dddfe2;
}

.ons-layout.dropdown > .box > .ons-item > .box {
  font-size: 13px;
  text-decoration: none;
  color: #385898;
}

.ons-layout.dropdown > .box > .ons-item > .box:hover {
  color: #5295f2;
}

/* überschrift */
.ons-overlay header > h1 {
  margin-left: 10px !important;
}

.ons-overlay header > h1,
.ons-heading.default > .box {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #385898;
  border-radius: var(--default-border-radius);
}

.ons-heading.payment > .box {
  display: block;
  margin: 15px 0 5px;
  font-size: 13px;
  font-weight: 600;
}

:not(.columns) > .box > .ons-heading.default:not(:last-child) > .box {
  margin-bottom: 10px;
}

/* group */
.ons-layout.group > .box,
.amx-group {
  padding: 10px;
  background-color: white;
  border: var(--default-border);
  border-radius: var(--default-border-radius);
}

onsw-games {
  padding: 0;
  background: transparent;
}

.ons-list > .box > .ons-layout.contact {
  padding: 2px 7px;
  height: 75px;
}

.ons-layout.group.columns > .box {
  margin: 0;
}

.ons-layout.group.columns.pad6 > .box {
  padding: 7px;
}

.ons-layout.group.columns.pad10 > .box {
  padding: 5px;
}

/* kacheln */

.ons-item.tile > .box {
  filter: drop-shadow(0 1px 2px rgba(0,0,0,.6));
}

.ons-item.tile > .box {
  color: white;
  font-weight: 600;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

.small-media-tile,
onsw-amateur-list > *,
.ons-list.amateurFriends > .box > * > .box,
.picture-box,
.livecam-tile-box,
.tile-amateur-box,
.media-box {
  background-color: white;
  border: var(--default-border);
  border-radius: var(--default-border-radius);
  overflow: hidden;
}

.media-widget > .box > .detail,
.tile-amateur-widget > .box > .detail {
  background: white;
}

[onsw-nickname-filter] > .box {
  padding: 0 7px 5px;
}

onsw-messenger-text-box textarea {
  resize: none;
}

.layout-container.messenger [onsc-message-count] > .box > .value {
  color: white;
  font-weight: 600;
  background: #c80000;
  font-size: 12px;
  line-height: 1;
  border-radius: var(--default-border-radius);
  box-shadow: 1px 1px 2px rgba(0,0,0,.4);
  padding: 2px 4px;
  animation: blubb .5s 2 alternate ease;
}

@keyframes blubb {
  from { transform: scale(1); background-color: #c80000; }
  to { transform: scale(1.4); background-color: #c80000; }
}

@media (min-width: 768px) {
  [hookname="main"] {
    border-right: var(--default-border);
  }
}

main.ons-scroll > .scroll-overlay > .scroll-top {
  border-radius: 50px;
  background: #5295f2;
  border: 1px solid #243963;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.4);
  bottom: 70px;
}

main.ons-scroll > .scroll-overlay > .scroll-top > .icon * {
  fill: white;
}

[onsw-rating] .outer {
  color: #dddfe2;
}

[onsw-rating].ready .outer > .inner {
  color: #ffd700;
}

.ons-layout.buttons {
  margin: 10px 0;
}

[onsw-amateur-common-filter] > .box > label:first-of-type {
  padding-top: 0;
}

p.small-feed-widget:first-of-type {
  color: #9a9a9a;
  font-size: 12px;
}

.ons-heading.default.small-feed-widget > .box {
  line-height: normal;
  margin-bottom: 5px !important;
}

.ons-heading.default.small-feed-widget:hover > .box {
  color: #5295f2;
}

[onsw-item].aotm-widget {
  font-size: 30px;
  font-weight: 600;
}

.ons-item.aotm-widget > .box > .value {
  font-size: 21px;
  font-weight: 400;
}

.ons-text.aotm-widget {
  font-size: 16px;
  line-height: 20px;
}

onsw-open-dialog-button.ons-item.tile > .box > .icon *,
onsw-open-dialog-button.ons-item.amateurToolbar > .box > .icon * {
  fill: #5295f2;
}

[onsw-loader] .box path,
[onsw-loader] .box circle {
  stroke: #5295f2;
}

.video-js .vjs-control-bar {
  background-color: rgba(36, 57, 99, .7) !important;
}
.video-js .vjs-load-progress {
  background: rgba(82, 149, 242, .4) !important;
}
.video-js .vjs-load-progress div {
  background: #5295f2 !important;
}

onsw-message-notification > .box {
  background-color: #5295f2;
  color: white;
}
onsw-message-notification > .box > .text {
  font-size: 14px;
}

onsw-template p.help-block,
.ons-form > form > p.small {
  color: #888;
  font-size: 11px;
  line-height: 1.4142;
}

[onsw-stock-indicator].expired > .box > .icon * {
  fill: #c80000;
}
[onsw-stock-indicator] > .box > .value > .icon * {
  fill: #ffd700;
}

/* table */
.table1 th {
  text-align: left;
  padding: 0 0 8px;
}
.table1 td {
  line-height: 24px;
}
.table1.table-lines td {
  border-top: var(--default-border);
  padding: 2px 0;
}
.table1 td:first-child {
  font-size: 14px;
  width: 45%;
}
onsw-latest-achievements.table1 .date {
  width:90px;
}
onsw-latest-achievements.table1 .value {
  width: 80px;
}
onsw-latest-achievements .icon {
  transform: scale(2) translate(2px, 0);
  width: 25px;
}
/*
onsw-points-to-coins.ons-form > form > label > span {
  font-size: 15px;
  color: #5295f2;
  font-weight: 600;
}
onsw-points-to-coins > form > label.notAvailable,
onsw-points-to-coins > form > label.notAvailable:hover {
  cursor: not-allowed;
  opacity: .3;
}
*/

.ons-image.error > .box {
  background-color: white;
}

.feed-box .ons-list > .box > * > .box {
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
}

[onsw-amateur-topic-badge] > .box .label {
  filter:drop-shadow(1px 1px 1px #024198);
  font-weight: 700;
  font-size: 13px;
}
[onsw-amateur-topic-badge] > .box .icon{
  filter:drop-shadow(0px 1px 0px #024198);
}

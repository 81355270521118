.ons-item.button > .box {
  text-decoration: none;
  padding: 0 8px;
  border: var(--default-btn-border);
  border-radius: var(--button-border-radius);
  color: var(--default-btn-text-color);
  background: var(--default-btn-bg);
  font: var(--default-btn-font);
  height: var(--default-btn-height);

  &:hover {
    background: var(--default-btn-hover-bg);
  }
}

.ons-item.button.cancel > .box,
.ons-list + .ons-item.button > .box {
  background: white;
  color: #385898;
  border: 1px solid #dddfe2;
}

.ons-item.button.cancel > .box:hover,
.ons-list + .ons-item.button > .box:hover {
  border: 1px solid #243963;
  color: white;
  background: #5295f2;
}

.ons-item.button.list-footer {
  margin-top: 10px;
  text-align: right;
}
.ons-item.button.list-footer.globalFeed {
  margin-top: 0;
}

/*.ons-item.button > .box > .icon {*/
/*  height: 28px;*/
/*  margin: 0 !important;*/
/*}*/

.ons-item.button > .box > .icon > * {
  fill: #fff;
}

.ons-item.button.cancel > .box > .icon > * {
  fill: #385898;
}
.layout-toolbar.toolbar-list > .box > [onsw-friend-operations] > .box > .buttons > .box > .ons-item.button > .box:hover > .icon * {
  fill: white !important;
}

[onsw-payment-packages].rebuy .package > .box {
  height: unset;
  line-height: inherit;
}

[onsw-payment-new-window] > .box > .buttons > .box > .ons-item.no > .box {
  background-color: #c80000;
}

[onsw-payment-new-window] > .box > .buttons > .box > .ons-item.no > .box:hover {
  background-color: #385898;
}
